<template>
  <div class="developer-container">
    <div class="header">
      <el-col :span="4">
        <el-row type="flex">
          <el-col :span="12">
            <el-input v-model="merchantId" placeholder="输入ID"></el-input>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="4">
        <el-row type="flex">
          <el-col :span="12">
            <el-input v-model="email" placeholder="输入邮箱"></el-input>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="3">
        <el-button type="primary" @click="search">搜索</el-button>
      </el-col>
    </div>
    <el-table :data="developerList.items" border stripe>
      <el-table-column
        v-for="col in columns"
        :key="col.id"
        :prop="col.id"
        :label="col.label"
        :width="col.width"
        :formatter="col.formatter"
      ></el-table-column>
      <el-table-column label="操作" width="300" align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="onClickAddNote(scope.row)"
            >备注</el-button
          >
          <el-button
            size="mini"
            type="primary"
            :disabled="scope.row.merchant_app.length === 0"
            @click="showDetail(scope.row)"
            >详细信息</el-button
          >
          <el-button
            v-if="+scope.row.status === 0 || +scope.row.status === -2"
            size="mini"
            type="primary"
            @click="handleUpdate(scope.row, 1)"
            >通过</el-button
          >
          <el-button
            v-if="+scope.row.status === 0"
            size="mini"
            type="info"
            @click="handleUpdate(scope.row, -1)"
            >拒绝</el-button
          >
          <!-- <el-button
            v-if="+scope.row.status === 1"
            size="mini"
            type="warning"
            @click="handleUpdate(scope.row, -2)"
            >冻结</el-button
          > -->
          <el-button
            :disabled="+scope.row.status === -3"
            size="mini"
            type="danger"
            @click="handleDelete(scope.row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="currentPage"
      :page-sizes="[30]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="developerList.total"
      background
      @size-change="sizeChange"
      @current-change="currentChange"
    ></el-pagination>

    <el-dialog
      title="详细信息"
      :close-on-click-modal="false"
      :visible.sync="detailVisible"
      width="50%"
      @close="closeDetail"
    >
      <el-form ref="form" :model="detailInfo" label-width="100px">
        <el-form-item label="应用名称">
          <el-input v-model="detailInfo.app_name"></el-input>
        </el-form-item>
        <el-form-item label="应用提供方">
          <el-input v-model="detailInfo.app_supply"></el-input>
        </el-form-item>
        <el-form-item label="下载链接">
          <el-input v-model="detailInfo.app_download_url"></el-input>
        </el-form-item>
        <el-form-item label="类型选择">
          <!-- <el-input v-model="detailInfo.app_type"></el-input> -->
          <el-radio-group v-model="detailInfo.app_type">
            <el-radio-button label="iOS">iOS版</el-radio-button>
            <el-radio-button label="Android">Android版</el-radio-button>
            <el-radio-button label="Web">Web 应用</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="应用图标">
          <el-upload
            action="https://jsonplaceholder.typicode.com/posts/"
            disabled
            list-type="picture-card"
            :file-list="appIconList"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="应用简介">
          <el-input
            v-model="detailInfo.app_info"
            type="textarea"
            maxlength="500"
            minlength="5"
            :autosize="{ minRows: 3 }"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="应用截图">
          <el-upload
            action="https://jsonplaceholder.typicode.com/posts/"
            disabled
            list-type="picture-card"
            :file-list="appImgList"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="应用分类">
          <el-select
            v-model="detailInfo.app_class"
            placeholder="请选择应用分类"
          >
            <el-option label="资产管理" value="资产管理"></el-option>
            <el-option label="挖矿算力" value="挖矿算力"></el-option>
            <el-option label="社交通讯" value="社交通讯"></el-option>
            <el-option label="游戏娱乐" value="游戏娱乐"></el-option>
            <el-option label="媒介资讯" value="媒介资讯"></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="closeDetail">取 消</el-button>
        <el-button type="primary" @click="detailConfirm">确定修改</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="请输入"
      :visible.sync="auditDialog.visible"
      width="30%"
      @close="closeAudit"
    >
      <el-form ref="auditForm" :model="auditDialog.data" label-width="80px">
        <el-form-item label="回调域名">
          <el-input v-model="auditDialog.data.merchant_domain"></el-input>
        </el-form-item>
        <el-form-item label="收款地址">
          <el-input
            v-model="auditDialog.data.receive_address"
            type="textarea"
            :autosize="{ minRows: 2 }"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="auditDialog.visible = false">取 消</el-button>
        <el-button type="primary" :disabled="!auditAble" @click="auditConfirm"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import config from "@/configs/index";

const origin = "";
// if (process.env.NODE_ENV === 'development') {
//   origin = '/dev'
// }

const STATUS = {
  0: "待审核",
  1: "审核通过",
  "-1": "审核不通过",
  "-2": "冻结",
  "-3": "删除",
};

export default {
  data() {
    return {
      dataList: [],
      columns: [
        { id: "merchant_id", label: "商家ID" },
        { id: "merchant_name", label: "商家名称" },
        {
          id: "merchant_type",
          label: "类型",
          formatter: (row) => {
            switch (row.merchant_type) {
              case 1:
                return "商家";
              case 2:
                return "个人";
              default:
                return row.merchant_type;
            }
          },
        },
        {
          id: "identity_id",
          label: "身份证号/公司名称",
          formatter: (row) => {
            switch (row.merchant_type) {
              case 1:
                return row.company_name;
              case 2:
                return row.identity_id;
              default:
                return "";
            }
          },
        },
        { id: "contact_mobile", label: "联系电话" },
        { id: "contact_name", label: "联系人" },
        { id: "contact_email", label: "邮箱" },
        { id: "status", label: "状态", formatter: (row) => STATUS[row.status] },
        { id: "info", label: "备注" },
      ],
      detailInfo: {},
      detailVisible: false,
      current: {},
      auditDialog: {
        visible: false,
        data: {},
      },
      currentPage: 1,
      pageSize: 30,
      merchantId: "",
      email: "",
    };
  },
  computed: {
    ...mapGetters(["developerList"]),
    appIconList() {
      if (this.detailInfo.app_icon) {
        return [
          {
            name: "app_icon",
            url: this.detailInfo.app_icon,
          },
        ];
      }
      return [];
    },
    appImgList() {
      let list = [];
      if (Array.isArray(this.detailInfo.app_img)) {
        list = this.detailInfo.app_img;
      } else {
        try {
          if (this.detailInfo.app_img === undefined) {
            console.log(this.detailInfo);
          }
          list = JSON.parse(this.detailInfo.app_img || "[]");
        } catch (error) {
          console.log(error);
        }
      }

      return list.map((url, index) => ({
        url,
        name: "app_img_" + index,
      }));
    },
    auditAble() {
      const { merchant_domain, receive_address } = this.auditDialog.data;
      return !!merchant_domain && !!receive_address;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    search(val) {
      this.currentPage = 1;
      this.fetchData();
    },
    fetchData() {
      const data = {
        page: this.currentPage,
        page_size: this.pageSize,
      };
      if (this.merchantId) {
        data.merchant_id = this.merchantId;
      }
      if (this.email) {
        data.email = this.email;
      }
      this.$store.dispatch("developer/getDeveloperList", data);
    },
    showDetail(row) {
      const appInfo = row.merchant_app && row.merchant_app[0];
      this.current = row;
      if (appInfo && appInfo.id) {
        this.detailInfo = appInfo;
        this.detailVisible = true;
      } else {
        this.$message({ message: "暂无信息" });
      }
    },
    closeDetail() {
      this.detailInfo = {};
      this.detailVisible = false;
    },
    showAudit(row) {
      this.auditDialog = {
        visible: true,
        data: {
          merchant_domain: "",
          receive_address: "",
          merchant_id: row.merchant_id,
          status: 1,
        },
      };
    },
    closeAudit() {
      this.auditDialog = {
        visible: false,
        data: {},
      };
    },
    detailConfirm() {
      const newAppInfo = {
        ...this.current,
        merchant_app: JSON.stringify([{ ...this.detailInfo }]),
      };
      this.$store.dispatch("developer/updateDeveloper", newAppInfo);
      this.closeDetail();
    },
    auditConfirm() {
      this.$store.dispatch("developer/auditDeveloer", this.auditDialog.data);
      this.closeAudit();
    },
    handleDelete(row) {
      this.$store.dispatch("developer/deleteDeveloper", row.merchant_id);
    },
    sizeChange(size) {
      this.pageSize = size;
      this.fetchData();
    },
    currentChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    async handleUpdate(row, status) {
      try {
        const data = {
          merchant_id: row.merchant_id,
          status,
        };
        const res = await axios.post(
          config.url + `/admin_wallet/api/openapi/checkMerchantInfo`,
          data
        );
        if (+res.data.code === 0) {
          this.$message({ message: "操作成功", type: "success" });
          this.fetchData();
        } else {
          this.$message({ message: res.data.msg, type: "error" });
        }
      } catch (error) {
        console.log(error);
        this.$message({ message: "操作失败", type: "error" });
      }
    },
    onClickAddNote(row) {
      this.$prompt("请输入备注", `提示`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "textarea",
        inputValue: row.info,
      }).then(({ value }) => {
        this.addNote(row.merchant_id, value);
      });
    },
    // 修改备注
    async addNote(merchant_id, note) {
      try {
        const data = { merchant_id, info: note };
        const res = await axios.post(
          config.url + `/admin_wallet/api/openapi/edit_apply`,
          data
        );
        if (+res.data.code === 0) {
          this.$message({ message: "操作成功", type: "success" });
          this.fetchData();
        } else {
          this.$message({ message: res.data.msg, type: "error" });
        }
      } catch (error) {}
    },
  },
};
</script>

<style lang="less" scoped>
.developer-container {
  margin: 20px;
}
</style>
